import axios from "@/axios.js";
/* eslint-disable */
export default {
  nexaAsk(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexa/chatbot`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  AllResult(page = 1) {
    return new Promise((resolve, reject) => {
      const types = [
        "chatbot",
        "nexa_document_insights",
        "nexa_deep_research",
        "nexa_compare_jurisdictions",
        "nexa_find_precedents",
        "nexa_analyze_pleadings",
        "nexa_build_argument",
        "nexa_build_memo",
      ];
      const queryParams = types
        .map((type) => `types[]=${encodeURIComponent(type)}`)
        .join("&");
      axios
        .get(`nexlaw?${queryParams}&page=${page}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  UseCaseResult(type, page = 1) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw?type=${type}&page=${page}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetResult(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  DetailAsk(id, requestBody) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexa/chatbot/${id}/ask`, requestBody)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  OpenSummary(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/jurisdiction/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendQuestion(type, id, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/case/${type}/${id}/ask`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  useCase(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexa/use-cases`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
