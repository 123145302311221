<template>
  <div style="position: relative">
    <div class="breadcrumb_header">
      <div class="breadcrumb">
        <h5 style="margin: 0">NeXa</h5>
        <span class="divider"></span>
        <span>Use Cases / Find Precedents</span>
      </div>
      <div class="open_sidebar cursor-pointer" @click="toggleSidebar">
        <span class="material-symbols-rounded icon"
          >keyboard_double_arrow_left</span
        >
        <p>Open Sidebar</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 mt-3">
        <div
          class="d-flex justify-content-start cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >

          <span style="margin: 0 8px">Back</span>
        </div>
      </div>
      <div class="col-md-10 mt-5">
        <loader v-if="loading" />
        <div v-else>
          <section class="uploaded-files">
            <h2 class="upload-count">Uploaded {{ files.length }} Files</h2>
            <button class="country-button">
              <img
                :src="countryImages[countryMapping[data.jurisdiction]]"
                :alt="`${data.jurisdiction} flag`"
                class="country-icon"
              />
              <span class="country-name">{{ data.jurisdiction }}</span>
            </button>
            <button class="view-files-button" @click="toggleSidebar">
              View Uploaded Files
            </button>
            <div class="security-info">
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ee5e1527866344adfafc18849c89c62dd76f2b9c7ded99add1ae660ece66da12?placeholderIfAbsent=true&apiKey=411e2ec61bdb45ecb3af3338badbc5df"
                alt=""
                class="info-icon"
              />
              <p class="info-text">
                Your files will be securely stored in NeXa, accessible only to
                you and your workspace admins, and you can delete them at any
                time.
              </p>
            </div>
          </section>
          <section class="analysis-section">
            <header class="analysis-heading">
              <div class="heading-title">
                <div class="tabs">Precedent Results</div>
              </div>
              <nav class="actions">
                <button
                  class="action-button copy-button"
                  tabindex="0"
                  @click="copy"
                >
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5fc1aca50cb553e4e813d1e66a4f62c328c94fc60417f9c7fb438b58331a1a1?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                    alt="Copy Icon"
                    class="icon"
                  />
                  <span class="button-label">{{
                    isCopied ? "Copied" : "Copy"
                  }}</span>
                </button>

                <b-dropdown
                  size="sm"
                  id="dropdown-right"
                  right
                  offset="-9"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  @click="toggleDropdown"
                >
                  <template #button-content>
                    <button class="action-button export-button">
                      <span class="button-label">Export</span>
                      <img
                        :class="{ 'rotate-180': isDropdown }"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d12668b437286b44ce443817869f8a85013f194276d09cf30b58481266006963?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                        alt="Export Icon"
                        class="icon"
                      />
                    </button>
                  </template>

                  <b-dropdown-item class="ms-1 me-1" @click="exportToPDF">
                    <img class="me-2" src="@/assets/icon/pdf-icon.svg" />
                    <span class="dropdown-text">Export as PDF</span>
                  </b-dropdown-item>

                  <b-dropdown-item class="ms-1 me-1" @click="exportToExcel">
                    <img class="me-2" src="@/assets/icon/excel-icon.svg" />
                    <span class="dropdown-text">Export as Excel</span>
                  </b-dropdown-item>
                </b-dropdown>
              </nav>
            </header>

            <section class="comparison-result">
              <table class="table-bordered" id="predecentTable">
                <thead>
                  <tr>
                    <th>Case Name</th>
                    <th>Citation</th>
                    <th>Court</th>
                    <th>Date Decided</th>
                    <th>Case Abstract</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(el, idx) in result"
                    :key="idx"
                  >
                  <!-- Hide click temporarily -->
                  <!-- <tr
                    v-for="(el, idx) in result"
                    :key="idx"
                    @click="
                      el
                        ? redirectToDetailPage(
                            el.content_key,
                            el.content_type,
                            el.name
                          )
                        : null
                    "
                  > -->
                    <template v-if="el">
                      <td>{{ el.name }}</td>
                      <td>{{ el.cite_no }}</td>

                      <td>{{ el.court }}</td>
                      <td>
                        {{ $options.filters.formatDateLoc(el.judgement_date) }}
                      </td>
                      <td>{{ el.abstract }}</td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </section>
          </section>
        </div>
      </div>
    </div>

    <sidebar
      v-if="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
      :uploadFiles="files"
    />
  </div>
</template>
<script>
import sidebar from "../sidebar.vue";
import countryImages from "./../../../assets/country/index";
import loader from "./../loader.vue";
import usecase from "@/store/usecase.js";
import * as XLSX from "xlsx";
import { ExportDataPDF } from "@/store/utils";
// import marked from "marked";
export default {
  components: {
    loader,
    sidebar,
  },
  data() {
    return {
      loading: true,
      isCopied: false,
      sidebarCollapsed: false,
      lawbotVisible: false,
      isCountry: false,
      isDropdown: false,
      countryImages,
      countryMapping: {
        Australia: "AUS",
        USA: "USA",
        Canada: "CAN",
        "New Zealand": "NZ",
        Singapore: "SG",
        Malaysia: "MY",
        "United Kingdom": "UK",
      },
      result: [],
      files: [],
      data: null,
      columns: [
        {
          label: "Case Name",
          field: "case_name",
        },
        {
          label: "Citation",
          field: "citation",
        },
        {
          label: "Court",
          field: "court",
        },
        {
          label: "Date Decided",
          field: "date_decided",
          dataFormat: this.$options.filters.formatDateLoc,
        },
        {
          label: "Case Abstract",
          field: "case_abstract",
        },
      ],
      excelData: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  created() {
    usecase.GetResult(this.$route.params.id ?? "").then((response) => {
      const DATA = response.data.data;
      if (DATA.completedPerCentage >= 100) {
        this.loading = false;
      }

      if (DATA.completedPerCentage < 100) {
        if (DATA.completedPerCentage > 0)
          this.duration = DATA.completedPerCentage;

        this.interval = setInterval(() => {
          usecase.GetResult(this.$route.params.id).then((res) => {
            if (res.data.data.completedPerCentage >= 100) {
              this.duration = res.data.data.completedPerCentage;
              clearInterval(this.interval);
              location.reload();
            } else if (res.data.data.completedPerCentage > 0) {
              this.duration = res.data.data.completedPerCentage;
            }
          });
        }, 10000);
      } else {
        const { values, questionDocuments, ...data } = DATA;
        this.data = data;
        this.files = questionDocuments;
        this.result = values.map((item) => {
          if (item != null) {
            return JSON.parse(item.value)[0];
          }
        });
      }
    });
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    toggleDropdown() {
      this.isDropdown = !this.isDropdown;
    },

    goBack() {
      this.$router.push({ name: "ExpertMode" });
    },
    redirectToDetailPage(key, type, title) {
      this.$router.push({
        name: "FindPrecedentDetail",
        params: { id: this.$route.params.id, title: title },
        query: { key: key, type: type },
      });
    },

    copy() {
      const tableElement = document.querySelector("table#predecentTable");

      if (!tableElement) {
        console.error("No table found in the provided HTML.");
        return;
      }

      let csvContent = "";
      const rows = tableElement.querySelectorAll("tr");

      rows.forEach((row) => {
        const cols = row.querySelectorAll("td, th");

        const rowArray = Array.from(cols).map((col) => col.innerText.trim());

        if (rowArray.some((cell) => cell !== "")) {
          csvContent += rowArray.join("\t") + "\n";
        }
      });

      navigator.clipboard
        .writeText(csvContent)
        .then(() => {
          this.$toast.success("Copied as plain text");
          this.isCopied = true;
        })
        .catch(() => {
          this.$toast.error("Error copying the text");
        })
        .finally(() => {
          setTimeout(() => {
            this.isCopied = false;
          }, 2000);
        });
    },
    exportToPDF() {
      let content;
      const followUpQuestion = `<h1 style="font-size: 24px; font-weight: bold;">Find Precedents: ${this.$route.params.id}</h1><br><hr>`;
      const tempDiv = document.createElement("div");

      // Style the temporary div
      tempDiv.style.width = "65%";
      tempDiv.style.maxWidth = "800px";
      tempDiv.style.margin = "20px auto";
      tempDiv.style.padding = "20px";
      tempDiv.style.border = "1px solid #ccc";
      tempDiv.style.borderRadius = "10px";
      tempDiv.style.backgroundColor = "#f9f9f9";
      tempDiv.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.1)";
      tempDiv.style.fontFamily = "Arial, sans-serif";
      content = document.querySelector("table#predecentTable");

      tempDiv.innerHTML =
        followUpQuestion + `<div class="content">${content.outerHTML}</div>`;

      document.body.appendChild(tempDiv);
      ExportDataPDF(
        tempDiv.innerHTML,
        false,
        `Find Precedent: ${this.$route.params.id}`
      );
      this.$toast.success("Successfully downloaded PDF");
      document.body.removeChild(tempDiv);
    },
    exportToExcel() {
      const tableElement = document.querySelector("table#predecentTable");

      if (!tableElement) {
        console.error("No table found in the provided HTML string.");
        return;
      }

      const rows = Array.from(tableElement.querySelectorAll("tr"));
      let csv = "";

      rows.forEach((row) => {
        const cols = Array.from(row.querySelectorAll("td, th"));

        if (
          cols.length > 0 &&
          cols.some((col) => col.innerText.trim() !== "")
        ) {
          const csvRow = cols
            .map((col) => `"${col.innerText.replace(/"/g, '""')}"`)
            .join(",");
          csv += csvRow + "\n";
        }
      });

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `Find Precedent : ${this.$route.params.id}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      const wb = XLSX.utils.table_to_book(tableElement, { sheet: "Sheet1" });

      XLSX.writeFile(wb, `Find Precedent : ${this.$route.params.id}.xlsx`);

      this.$toast.success(
        `Exported Find Precedents ${this.$route.params.id} to CSV and XLSX`
      );
    },
  },
  computed: {
    filteredCountryImages() {
      return Object.entries(this.countryImages).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    },
  },
};
</script>

<style scoped>
.uploaded-files {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  padding: 16px 20px;
  gap: 1rem;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  color: #86888d;
  font: 500 14px/1 Poppins, sans-serif;

  margin-bottom: 2rem;
}

.upload-count {
  width: 100%;
  max-width: 20rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.4;
  color: #383a3e;
}

.overlay-container {
  width: 25%;
  position: fixed;
  top: 8.7vh;
  right: 0;
  z-index: 1000;

  height: 100vh;
}

.country-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  padding: 2px 8px;
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
  background: none;
  border: none;
}

.country-icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.view-files-button {
  align-self: start;
  margin-top: 16px;
  padding: 2px 8px;
  border-radius: 4px;
  color: #1890ff;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  overflow: hidden;
}

.view-files-button:focus {
  color: #383a3e;
}

.security-info {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.info-icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.info-text {
  align-self: stretch;
  margin: 0;
}

@media (max-width: 991px) {
  .country-button {
    white-space: normal;
  }

  .security-info,
  .info-text {
    max-width: 100%;
  }
}

.page-content {
  padding: 0;
}
.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: #fff;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
  span {
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}
.open_sidebar {
  height: 40px;
  background: var(--primary);
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.back {
  color: var(--primary) !important;
  font-weight: 500;
  padding: 5px;
}
.col-md-10 {
  /* Allows vertical scrolling if content exceeds the height */
  max-height: 100vh; /* Ensure it doesn’t exceed the viewport height */
}
.content {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 4px 0 rgba(170, 171, 175, 0.6);
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
}
@media (max-width: 991px) {
  .content {
    padding: 0 20px;
  }
}
</style>
<style scoped>
.analysis-section {
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(227, 229, 235, 0.6);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  padding: 20px 24px;
}
@media (max-width: 991px) {
  .analysis-section {
    padding: 0 20px;
  }
}
.analysis-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.heading-title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 28px;
}
.tabs {
  color: #0e4485;
}
.tabs-2 {
  color: #383a3e;
}
.actions {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
}
.action-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}
.copy-button {
  color: #0e4485;
}
.export-button {
  background: #0e4485;
  color: #fff;
}
.icon {
  width: 18px;
  object-fit: contain;
}
.comparison-result {
  border-radius: 8px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}
.result-header {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  background: #f2f3f3;
  padding: 12px 16px;
}
.result-section,
.result-claim,
.result-defense,
.result-comparison,
.result-comments {
  flex: 1;
  border-right: 1px solid #86888d;
  padding: 9px 16px;
}
.result-comments {
  border-right: none;
}
.result-item {
  display: flex;
  padding: 12px 16px;
  border-bottom: 1px solid #86888d;
}
.item-section,
.item-claim,
.item-defense,
.item-comparison,
.item-comments {
  flex: 1;
  padding: 9px 16px;
  border-right: 1px solid #86888d;
}
.item-comments {
  border-right: none;
}
.dropdown-container {
  display: flex;
  flex-direction: column;
  padding: 10px 8px;
  position: absolute;
  width: 150px;
  background: white;
  border: 1px solid #cacaca;
  top: 50px;
  right: 60px;
  box-shadow: 0px 4px 10px rgba(170, 171, 175, 0.6);
  border-radius: 8px;
}
.dropdown-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
}
.export-confirm-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background: #0e4485;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
table {
  width: 100%;
}

th,
td {
  text-align: left;
  padding: 12px;
  font-size: 14px;
}

thead th {
  background-color: #f5f5f5;
  font-weight: bold;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.country-flag {
  width: 24px;
  height: 24px;
}
</style>
